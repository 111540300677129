import React from "react"
import { Container, Row, Col, Badge } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"

const FeaturePracticeManagement = () => (
  <Layout>
    <Seo
      title="Carebit: Terms of Service"
      pathname="/terms-of-service"
      description="Learn about our terms of service."
    />
    <div className="u-centerFlex u-textCenter Hero">
      <Container>
        <Row>
          <Col
            lg={{ size: 6, offset: 3 }}
            md={{ size: 10, offset: 1 }}
            style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
          >
            <Badge color="warning" className="u-marginBottom20">
              Terms of service
            </Badge>

            <h1>Terms of service</h1>
            <p className="u-whiteTranslucent">
              Before you start using our services, we want to make sure you
              understand the rules and guidelines that govern your use of our
              platform. These Terms of Service outline the legal agreement
              between you and Carebit Health Limited, covering important aspects
              like account responsibilities, acceptable use and more. By using
              Carebit, you agree to these terms, so please take a moment to read
              through them.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
    <div>
      <Container>
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <section>
              <Row className="u-centerFlex">
                <Col lg={6} md={12}>
                  <Row>
                    <Heading title="Terms of service">
                      This is the Terms of Service for carebit.co (the
                      “Website”). It was last updated on March 18th, 2024.
                    </Heading>
                  </Row>

                  <div className="row">
                    <div className="col-md-12 u-marginTop20">
                      <h3>1. Acceptance of Terms</h3>
                      <p>
                        By accessing or using Carebit ("Service"), provided by
                        Carebit Health Limited ("Company", "we", "us", or
                        "our"), you agree to comply with and be bound by these
                        Terms of Service ("Terms"). If you do not agree with
                        these Terms, you should not use the Service.
                      </p>

                      <h3>2. Modifications to Terms</h3>
                      <p>
                        We reserve the right to modify these Terms at any time.
                        Any changes will be effective immediately upon posting
                        the updated Terms on our website. Your continued use of
                        the Service after any changes to the Terms indicates
                        your acceptance of those changes.
                      </p>

                      <h3>3. User Accounts</h3>
                      <p>
                        To use certain features of the Service, you may be
                        required to create an account. You agree to provide
                        accurate and complete information when creating your
                        account and to update such information as necessary. You
                        are responsible for maintaining the confidentiality of
                        your account credentials and for all activities that
                        occur under your account.
                      </p>

                      <h3>4. Use of the Service</h3>
                      <p>
                        You agree to use the Service only for lawful purposes
                        and in compliance with all applicable laws and
                        regulations. You shall not:
                      </p>
                      <ul>
                        <li>
                          Use the Service for any illegal or unauthorised
                          purpose.
                        </li>
                        <li>
                          Distribute viruses, malware, or any other harmful
                          code.
                        </li>
                        <li>
                          Engage in any activity that interferes with or
                          disrupts the Service.
                        </li>
                        <li>
                          Attempt to gain unauthorised access to the Service or
                          its related systems.
                        </li>
                      </ul>

                      <h3>5. Termination</h3>
                      <p>
                        We may suspend or terminate your access to the Service
                        at any time, without notice, for conduct that we believe
                        violates these Terms or is harmful to other users of the
                        Service, us, or third parties, or for any other reason
                        in our sole discretion.
                      </p>

                      <h3>6. Intellectual Property</h3>
                      <p>
                        All content, trademarks, and other intellectual property
                        available through the Service are the property of
                        Carebit Health Limited or its licensors. You may not
                        copy, modify, distribute, or use any of the intellectual
                        property except as expressly authorised by these Terms
                        or with prior written permission from us.
                      </p>

                      <h3>7. Disclaimer of Warranties</h3>
                      <p>
                        The Service is provided on an "as is" and "as available"
                        basis. Carebit Health Limited makes no warranties,
                        express or implied, regarding the Service, including but
                        not limited to implied warranties of merchantability,
                        fitness for a particular purpose, or non-infringement.
                      </p>

                      <h3>8. Limitation of Liability</h3>
                      <p>
                        In no event shall Carebit Health Limited be liable for
                        any indirect, incidental, special, consequential, or
                        punitive damages, or any loss of profits or revenues,
                        whether incurred directly or indirectly, or any loss of
                        data, use, goodwill, or other intangible losses,
                        resulting from (a) your use or inability to use the
                        Service; (b) any unauthorised access to or use of our
                        servers and/or any personal information stored therein;
                        (c) any interruption or cessation of transmission to or
                        from the Service; (d) any bugs, viruses, trojan horses,
                        or the like that may be transmitted to or through the
                        Service by any third party; or (e) any errors or
                        omissions in any content or for any loss or damage of
                        any kind incurred as a result of your use of any content
                        posted, emailed, transmitted, or otherwise made
                        available via the Service, whether based on warranty,
                        contract, tort, or any other legal theory, and whether
                        or not we are advised of the possibility of such
                        damages.
                      </p>

                      <h3>9. Governing Law</h3>
                      <p>
                        These Terms shall be governed by and construed in
                        accordance with the laws of the United Kingdom, without
                        regard to its conflict of law principles. You agree to
                        submit to the exclusive jurisdiction of the courts
                        located in the United Kingdom to resolve any legal
                        matter arising from these Terms.
                      </p>

                      <h3>10. Contact Information</h3>
                      <p>
                        If you have any questions about these Terms, please
                        contact us at:
                      </p>

                      <p>Carebit Health Limited</p>
                      <p>63 Bermondsey Street, London, England, SE1 3XF</p>
                      <p>
                        <a href="mailto:info@carebit.co">info@carebit.co</a>{" "}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default FeaturePracticeManagement
